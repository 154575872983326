import { ModelType } from '~/app/base/schemas/BaseSchema';
import EndpointFactory, { ENDPOINT_CONSTRUCTOR } from '~/app/factories/EndpointFactory';
import { Enum } from '~~/utils/enum';

export const NOTIFICATION_TYPE = {
    PLANNING_TIME_ACTIVITY_ASSIGNED: 'planning:time-activity:assigned',
    PLANNING_TIME_ACTIVITY_CREATED: 'planning:time-activity:created',
    PLANNING_TIME_ACTIVITY_DELETED: 'planning:time-activity:deleted',
    PLANNING_TIME_ACTIVITY_PAUSED: 'planning:time-activity:paused',
    PLANNING_TIME_ACTIVITY_RESTARTED: 'planning:time-activity:restarted',
    PLANNING_TIME_ACTIVITY_STARTED: 'planning:time-activity:started',
    PLANNING_TIME_ACTIVITY_STOPPED: 'planning:time-activity:stopped',
    PLANNING_TIME_ACTIVITY_MESSAGE_RECEIVED: 'planning:time-activity:message-received',

    PLANNING_CALENDAR_EVENT_ASSIGNED: 'planning:calendar-event:assigned',
    PLANNING_CALENDAR_EVENT_CREATED: 'planning:calendar-event:created',

    TASK_TASK_ASSIGNED: 'task:task:assigned',
    TASK_TASK_COMPLETED: 'task:task:completed',
    TASK_TASK_CREATED: 'task:task:created',
    TASK_TASK_UNCOMPLETED: 'task:task:uncompleted',
    TASK_TASK_MESSAGE_RECEIVED: 'task:task:message-received',

    WORKSITE_WORKSITE_MESSAGE_RECEIVED: 'worksite:worksite:message-received',

    QUOTE_ACCEPTED_BY_CLIENT: 'invoicing:quote:accepted-by-client',
    QUOTE_DECLINED_BY_CLIENT: 'invoicing:quote:declined-by-client',
    INVOICE_MARKED_AS_OVERDUE: 'invoicing:invoice:marked-as-overdue',
    INVOICE_PAYMENT_RECORDED_FROM_BANK_TRANSACTION: 'invoicing:invoice:payment-recorded-from-bank-transaction',
    WORKSITE_PROGRESS_BILLING_ACCEPTED_BY_CLIENT: 'worksite:worksite-progress-billing:accepted-by-client',
} as const;

export const NOTIFICATION_TYPE_MODEL_TYPE = {
    [NOTIFICATION_TYPE.PLANNING_TIME_ACTIVITY_ASSIGNED]: ModelType.TIME_ACTIVITIES,
    [NOTIFICATION_TYPE.PLANNING_TIME_ACTIVITY_CREATED]: ModelType.TIME_ACTIVITIES,
    [NOTIFICATION_TYPE.PLANNING_TIME_ACTIVITY_DELETED]: ModelType.TIME_ACTIVITIES,
    [NOTIFICATION_TYPE.PLANNING_TIME_ACTIVITY_PAUSED]: ModelType.TIME_ACTIVITIES,
    [NOTIFICATION_TYPE.PLANNING_TIME_ACTIVITY_RESTARTED]: ModelType.TIME_ACTIVITIES,
    [NOTIFICATION_TYPE.PLANNING_TIME_ACTIVITY_STARTED]: ModelType.TIME_ACTIVITIES,
    [NOTIFICATION_TYPE.PLANNING_TIME_ACTIVITY_STOPPED]: ModelType.TIME_ACTIVITIES,
    [NOTIFICATION_TYPE.PLANNING_TIME_ACTIVITY_MESSAGE_RECEIVED]: ModelType.TIME_ACTIVITIES,

    [NOTIFICATION_TYPE.PLANNING_CALENDAR_EVENT_ASSIGNED]: ModelType.CALENDAR_EVENTS,
    [NOTIFICATION_TYPE.PLANNING_CALENDAR_EVENT_CREATED]: ModelType.CALENDAR_EVENTS,

    [NOTIFICATION_TYPE.TASK_TASK_ASSIGNED]: ModelType.TASKS,
    [NOTIFICATION_TYPE.TASK_TASK_COMPLETED]: ModelType.TASKS,
    [NOTIFICATION_TYPE.TASK_TASK_CREATED]: ModelType.TASKS,
    [NOTIFICATION_TYPE.TASK_TASK_UNCOMPLETED]: ModelType.TASKS,
    [NOTIFICATION_TYPE.TASK_TASK_MESSAGE_RECEIVED]: ModelType.TASKS,

    [NOTIFICATION_TYPE.WORKSITE_WORKSITE_MESSAGE_RECEIVED]: ModelType.WORKSITES,

    [NOTIFICATION_TYPE.QUOTE_ACCEPTED_BY_CLIENT]: ModelType.INVOICES,
    [NOTIFICATION_TYPE.QUOTE_DECLINED_BY_CLIENT]: ModelType.INVOICES,
    [NOTIFICATION_TYPE.INVOICE_MARKED_AS_OVERDUE]: ModelType.INVOICES,
    [NOTIFICATION_TYPE.INVOICE_PAYMENT_RECORDED_FROM_BANK_TRANSACTION]: ModelType.INVOICES,
    [NOTIFICATION_TYPE.WORKSITE_PROGRESS_BILLING_ACCEPTED_BY_CLIENT]: ModelType.WORKSITE_PROGRESS_BILLINGS,
} as const;

export type NotificationType = Enum<typeof NOTIFICATION_TYPE>;

const useNotification = <TNotificationType extends NotificationType>(type: TNotificationType) => {
    const related = async (uuid: string): Promise<Awaited<ReturnType<InstanceType<(typeof ENDPOINT_CONSTRUCTOR)[(typeof NOTIFICATION_TYPE_MODEL_TYPE)[TNotificationType]]>['retrieve']>>['data']> => {
        const modelType = NOTIFICATION_TYPE_MODEL_TYPE[type];
        const endpoint = EndpointFactory.make(modelType);
        const { data } = await endpoint.retrieve(uuid);
        return data;
    };
    const preview = async (uuid: string) => {
        const model = await related(uuid);
        model?.preview();
    };

    return { preview, related };
};

const { preview, related } = useNotification('planning:calendar-event:assigned');
