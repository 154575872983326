import useLocalStorage from './useLocalStorage';
import { NotificationType } from '~~/app/common/enums/NotificationType';

const useNotificationModal = () => {
    const { setValue, getValue, resetValue } = useLocalStorage<{
        notificationType: NotificationType;
        relatedToId: string;
    }>('notification-modal');
    const openModal = async (): Promise<void> => {
        const value = getValue();
        resetValue();
        if (!value) return;
        const { notificationType, relatedToId } = value;
        const { preview } = useNotification(notificationType);
        return await preview(relatedToId);
    };

    return { setValue, openModal };
};

export default useNotificationModal;
