import { NOTIFICATION_TYPE_MODEL_TYPE, NotificationType } from '~~/app/common/enums/NotificationType';
import EndpointFactory, { ENDPOINT_CONSTRUCTOR } from '~~/app/factories/EndpointFactory';

const useNotification = <TNotificationType extends NotificationType>(type: TNotificationType) => {
    const related = async (uuid: string): Promise<Awaited<ReturnType<InstanceType<(typeof ENDPOINT_CONSTRUCTOR)[(typeof NOTIFICATION_TYPE_MODEL_TYPE)[TNotificationType]]>['retrieve']>>['data']> => {
        const modelType = NOTIFICATION_TYPE_MODEL_TYPE[type];
        const endpoint = EndpointFactory.make(modelType);
        const { data } = await endpoint.retrieve(uuid);
        return data;
    };
    const preview = async (uuid: string) => {
        const model = await related(uuid);
        model?.preview();
    };

    return { preview, related };
};

export default useNotification;
